.domain-url-input {
    width: 81.5%;
}

.domain-url-input-checking {
    width: 76.5%;
}

.domain-url-check-button {
    margin-left: 3%;
}

.error-message-span {
    color: red;
}
